//
// Page - User Login 1
// Pages SASS files are compiled into separate css files
//

// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Login Base
.kt-login.kt-login--v1 {
    background-size: calc(75% - var(--x)/15);
    background-position-y:10%;

  // Aside
  .kt-login__aside {
    width: 605px;
    padding: 3rem 3.5rem;

    .kt-login__image {
      position: fixed;
      bottom: 0;
      right: 50%;
      min-height: 70vh;
      max-height: 90vh;
    }
    .kt-login__logo {
      display: flex;
      margin-left: 2rem;
    }
    .kt-login__subtitle {
      font-size: 1.2rem;
      font-weight: 200;
      margin: 2.5rem 0 3.5rem 0;
      color: #b5b2c3;
    }

    .kt-login__info {
      display: flex;
      justify-content: space-between;

      .kt-login__menu {
        > a {
          text-decoration: none;
          color: #fff;
          margin-right: 2rem;
          display: inline-block;

          @include kt-link-color(rgba(#fff, 0.7), #fff);

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .kt-login__copyright {
        color: rgba(#fff, 0.4);
      }
    }
  }
  // Wrapper
  .kt-login__wrapper {
    padding: 3rem 10% 0rem 10%;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: -10px -10px 200px -10px black;
    position: relative;
    z-index:10;
    // Head
    .kt-login__head {
      font-size: 1rem;
      font-weight: 500;
      text-align: left;

      .kt-login__signup-label {
        color: kt-base-color(label, 2);
      }

      .kt-login__signup-link {
        color: kt-brand-color();
      }
    }
    .kt-login__title {
      color: #000;
      font-size: 3rem;
      font-weight: 400;
      margin: 10% 0;
    }
    .kt-login__footer_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      flex: 1;

      .kt-login__footer_text{
        text-align: left;
        width:100%;
      }

      .kt-login__footer {
        margin-block: 3rem;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    // Body
    .kt-login__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;

      // Form Wrapper
      .kt-login__form {
        width: 100%;

        .kt-login__title {
          display: block;
          text-align: center;
          margin-bottom: 5rem;
          text-decoration: none;

          > h3 {
            font-size: 2rem;
            color: #67666e;
          }
        }

        .kt-login__subtitle {
          font-size: 1.2rem;
          font-weight: 200;
          color: #a6a6a6;
        }

        .kt-login__google {
          width: 100%;
          justify-content: center;
        }
        .kt-google__container {
          width: 100%;
          border: 1px solid #000;
          border-radius: 0.3rem;
          justify-content: center;
        }
        // Form
        .kt-form {
          margin: 0 auto;

          .form-group {
            margin: 0;
            padding: 0;
            margin: 0 auto;

            .form-control {
              border: none;
              height: 50px;
              margin-top: 1.25rem;
              background-color: rgba(#f7f7f9, 0.7);
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              transition: background-color 0.3s ease;

              &:focus {
                transition: background-color 0.3s ease;
                background-color: #f7f7f9;
              }
            }
          }
        }

        // Action
        .kt-login__actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;
          margin: 3rem 0;

          .kt-login__link-forgot {
            font-weight: 400;
            @include kt-link-color(kt-base-color(label, 2), kt-brand-color());
          }

          .kt-login__btn-secondary,
          .kt-login__btn-primary {
            font-weight: 500;
            padding: 1.2rem;
            font-size: large;
            border-radius: 10px;
            border-width: 0px;
            background-color: #1a1a1a;
            width: 100%;
          }
        }

        // Divider
        .kt-login__divider {
          margin: 1rem 0 2rem 0;

          &:not(:first-child):not(:last-child) {
            font-weight: 400;
            color: #b5b2c3;
            font-size: 1rem;
          }
        }

        // Options
        .kt-login__options {
          display: flex;
          justify-content: center;
          justify-content: space-between;
          max-width: 100%;

          > a {
            text-decoration: none;
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;

            &:not(:last-child) {
              margin: 0 1.5rem 0 0;
            }
          }
        }
      }
    }
  }

  @include kt-desktop {
    // Aside
    .kt-login__aside {
      flex: 1;
    }
  }

  @include kt-tablet-and-mobile() {
    // Aside
    .kt-login__aside {
      width: 100%;
      height: auto;
      padding: 2rem 1.5rem;

      .kt-login__logo {
        margin-bottom: 2.5rem;
        margin-left: 1rem;
      }

      .kt-login__info {
        margin-top: 2rem;
      }

      .kt-login__subtitle {
        margin: 2rem 0;
      }
    }
    .kt-login__image {
      display: none;
    }

    // Wrapper
    .kt-login__wrapper {
      padding: 3rem 3rem;

      .kt-login__footer {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .kt-login__title{
        margin-block: 1rem;
      }
      .kt-login__body {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;

        .kt-login__form {
          .kt-login__options {
            > a {
              &:not(:first-child):not(:last-child) {
                margin: 0 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
