.tableContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tableContainer {
    max-height: 60vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}