.ig-date-picker {
  .MuiOutlinedInput-root {
    margin-right: 2.5rem;
  }

  .MuiInputAdornment-positionEnd {
    position: absolute;
    width: 100%;

    button {
      width: 100%;
      border-radius: 0;
      justify-content: flex-end;
      right: 8px;
    }
  }
}