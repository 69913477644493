#noc * {
  font-family: 'docs-Calibri', Arial;;
}
#noc ol {
  margin: 0;
  padding: 0
}

#noc table td,
table th {
  padding: 0
}

#noc .c40 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #ffffff;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 149.2pt;
  border-top-color: #ffffff;
  border-bottom-style: solid
}

#noc .c5 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #ffffff;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 111.8pt;
  border-top-color: #ffffff;
  border-bottom-style: solid
}

#noc .c37 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #ffffff;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 101.2pt;
  border-top-color: #ffffff;
  border-bottom-style: solid
}

#noc .c46 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #ffffff;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 144.8pt;
  border-top-color: #ffffff;
  border-bottom-style: solid
}

#noc .c11 {
  background-color: #ffffff;
  color: #2c3639;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-style: normal
}

#noc .c14 {
  background-color: #ffffff;
  color: #334155;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-family: "Cambria";
  font-style: normal
}

#noc .c10 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-style: normal
}

#noc .c6 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-style: normal
}

#noc .c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-style: normal
}

#noc .c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18.5pt;
  font-style: normal
}

#noc .c7 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12px;
  font-family: "Times New Roman";
  font-style: normal
}

#noc .c17 {
  margin-left: 7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.308333333333333;
  text-align: left;
  margin-right: 6pt
}

#noc .c18 {
  margin-left: 7.1pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1583333333333334;
  text-align: left;
  margin-right: 19.1pt
}

#noc .c1 {
  margin-left: 7.1pt;
  padding-top: 3.8pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left;
  height: 11pt
}

#noc .c45 {
  margin-left: 42.8pt;
  padding-top: 0pt;
  text-indent: -0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: right
}

#noc .c31 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal
}

#noc .c4 {
  padding-top: 0.9pt;
  padding-bottom: 0pt;
  line-height: 1.0875000000000001;
  text-align: justify;
  margin-right: 49.9pt;
  height: 11pt
}

#noc .c8 {
  margin-left: 7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 19.1pt
}

#noc .c13 {
  margin-left: 7.1pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left;
  height: 11pt
}

#noc .c26 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1583333333333334;
  text-align: left;
  margin-right: 19.1pt
}

#noc .c41 {
  padding-top: 1.1pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left;
  margin-right: -1.9pt
}

#noc .c3 {
  padding-top: 0.9pt;
  padding-bottom: 0pt;
  line-height: 1.0875000000000001;
  text-align: justify;
  margin-right: 2.9pt;
  margin-bottom: 0;
}

#noc .c35 {
  padding-top: 0.9pt;
  padding-bottom: 0pt;
  line-height: 1.0875000000000001;
  text-align: justify;
}

#noc .c44 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.308333333333333;
  text-align: left;
  margin-right: 6.1pt
}

#noc .c33 {
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: right;
  margin-right: -0.1pt
}

#noc .c25 {
  padding-top: 9.2pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left
}

#noc .c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left
}

#noc .c15 {
  padding-top: 1.5pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: justify;
  margin-bottom: 0;
}

#noc .c34 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

#noc .c50 {
  padding-top: 6.4pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left
}

#noc .c39 {
  padding-bottom: 0pt;
  line-height: 1.3375000000000001;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
}

#noc .c36 {
  padding-top: 0.9pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left;
  margin-bottom: 0;
  margin-top: 1rem;
}

#noc .c24 {
  padding-top: 1.5pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left;
  margin-bottom: 0;

}

#noc .c38 {
  background-color: #ffffff;
  color: #2c3639
}

.c21 {
  background-color: #ffffff;
  max-width: 510.2pt;
  padding: 17pt 39.1pt 0pt 39.1pt
}

#noc .c43 {
  letter-spacing: 1.1px;
}

#noc .c32 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-underline-offset: 4px;
}

#noc .c49 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal
}

#noc .c28 {
  color: inherit;
  text-decoration: inherit
}

#noc .c48 {
  margin-left: 42.8pt;
  text-indent: -0pt
}

#noc .c20 {
  margin-left: 7.1pt;
}

#noc .c19 {
  color: #467785
}

#noc .c42 {
  color: #000000
}

#noc .c16 {
  font-size: 12px
}

#noc .c22 {
  height: 11pt
}

#noc .c29 {
  font-weight: 700
}

#noc .c12 {
  color: #231f1f
}

#noc .c47 {
  height: 0pt
}

#noc .c27 {
  margin-left: 6.8pt
}

#noc .c23 {
  margin-left: 7.1pt
}

#noc .c30 {
  vertical-align: super
}

#noc .title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 18.5pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left
}

#noc .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0;
  page-break-after: avoid;
  font-style: italic;
  text-align: left
}

#noc li {
  color: #000000;
  font-size: 11pt;
}

#noc p {
  color: #000000;
  font-size: 11pt;
}

#noc h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

#noc h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

#noc h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

#noc h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

#noc h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

#noc h6 {
  padding-top: 12px;
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  padding-bottom: 2pt;
  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}