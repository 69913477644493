@import "./_metronic/_assets/sass/style.react.scss";

#webpack-dev-server-client-overlay {
  display: none !important;
}

// Apply scrollbar styles
main::-webkit-scrollbar,
div::-webkit-scrollbar,
body::-webkit-scrollbar,
table::-webkit-scrollbar,
html::-webkit-scrollbar,
nav::-webkit-scrollbar,
ul::-webkit-scrollbar,
li::-webkit-scrollbar,
ol::-webkit-scrollbar,
dl::-webkit-scrollbar {
  width: 0;
}
