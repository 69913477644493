.waffle {
  padding: 10px;
  text-align: left;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  border-collapse: initial;
}

.waffle tr {
  display: flex;
}

.waffle td {
  padding: 5px 8px !important;
  width: 100%;
  line-height: 22px;
}

.label {
  background-color: #f3f3f3;
  border-bottom: 1px SOLID grey;
  max-width: 150px;
  min-width: 150px;
}

.value {
  font-weight: 700;
  background-color: white;
  min-width: 200px;
  border-bottom: 1px SOLID grey;
}

.left-border {
  border-left: 1px solid black;
}

.right-border {
  border-right: 1px solid black;
}

.bottom-border {
  border-bottom: 1px solid black;
}

.ritz .waffle a {
  color: inherit;
}

.ritz .waffle .s2 {
  border-bottom: 1px SOLID #000000;
  background-color: #ffffff;
}

.ritz .waffle .s18 {
  border-right: 1px SOLID #000000;
  background-color: #000000;
  text-align: left;
  font-weight: bold;
  color: #ffffff;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s12 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s8 {
  border-bottom: 1px SOLID #000000;
  border-right: 1px SOLID #000000;
  background-color: #f3f3f3;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s0 {
  border-bottom: 1px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 0;
}

.ritz .waffle .s9 {
  border-right: none;
  border-bottom: 1px SOLID #000000;
  background-color: #f3f3f3;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s14 {
  border-right: none;
  border-bottom: 1px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s1 {
  border-bottom: 1px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s11 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s19 {
  background-color: #ffffff;
  text-align: left;
  font-style: italic;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}

.ritz .waffle .s5 {
  border-right: none;
  border-bottom: 1px SOLID #000000;
  background-color: #c0c0c0;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-Calibri', Arial;
  font-size: 11pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0px 3px 0px 3px;
}